import "./App.css";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { ThirdPartyPreBuiltUI } from "supertokens-auth-react/recipe/thirdparty/prebuiltui";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./Home";
import { SuperTokensConfig } from "./config";
import { Goto } from "./Home/Goto";
import { RefreshToken } from "./Home/RefreshToken";
import { RefreshTokenSuccess } from "./Home/RefreshTokenSuccess";
import LogoutPage from "./Home/LogoutPage";
import { AdminPage } from "./Admin";

SuperTokens.init(SuperTokensConfig);

function App() {
    return (
        <SuperTokensWrapper>
            <div className="App">
                <Router>
                    <div className="fill">
                        <Routes>
                            {/* This shows the login UI on "/auth" route */}
                            {getSuperTokensRoutesForReactRouterDom(require("react-router-dom"), [
                                ThirdPartyPreBuiltUI,
                            ])}
                            <Route
                                path="/"
                                element={
                                    /* This protects the "/" route so that it shows
                                  <Home /> only if the user is logged in.
                                  Else it redirects the user to "/auth" */
                                    <SessionAuth>
                                        <Home />
                                    </SessionAuth>
                                }
                            />
                            <Route
                                path="/Logout"
                                element={
                                    /* This protects the "/" route so that it shows
                                  <Home /> only if the user is logged in.
                                  Else it redirects the user to "/auth" */
                                    <SessionAuth>
                                        <LogoutPage />
                                    </SessionAuth>
                                }
                            />

                            <Route
                                path="/Goto/:url"
                                element={
                                    /* This protects the "/" route so that it shows
                                  <Home /> only if the user is logged in.
                                  Else it redirects the user to "/auth" */
                                    <SessionAuth>
                                        <Goto />
                                    </SessionAuth>
                                }
                            />
                            <Route
                                path="/RefreshToken"
                                /* 
                                    this page does not require user logged in, and it's intended to put in an Iframe where Microsoft login page won't work:
                                    https://learn.microsoft.com/en-us/archive/blogs/richard_dizeregas_blog/connecting-to-office-365-from-an-office-add-in

                                        Both of these constraints can be challenging when performing OAuth flows. 
                                        Due to the popularity of clickjacking on the internet, it is common to 
                                        prevent login pages from being display inside frames. The X-FRAME-Options
                                        meta tag in HTML makes it easy for providers to implement this safeguard 
                                        on a widespread or domain/origin-specific basis.
                                    When there is no session associated, we will ask the user to click the login page on a new tab
                                 */
                                element={<RefreshToken />}
                            />
                            <Route
                                path="/RefreshTokenSuccess"
                                element={
                                    <SessionAuth>
                                        <RefreshTokenSuccess />
                                    </SessionAuth>
                                }
                            />

                            <Route path="/admin/*" element={
                                <SessionAuth>
                                    <AdminPage />
                                </SessionAuth>
                            } />

                        </Routes>
                    </div>
                </Router>
            </div>
        </SuperTokensWrapper>
    );
}

export default App;
