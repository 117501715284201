import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';

export const RefreshTokenSuccess: React.FC = () => {
    const location = useLocation();
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (window.opener) {
            console.log(`RefreshTokenSuccess: Sending message to opener`);
            window.opener.postMessage({
                name: "RefreshTokenSuccess"
            }, "*");
        }
        else {
            console.log(`RefreshTokenSuccess: No opener found`);
        }
        const closeTab = queryParams.get('closeTab');
        if (closeTab === 'true') {
            console.log(`RefreshTokenSuccess: Closing the tab`);
            debugger; // allow debugger a change before closing the tab.        
            window.setTimeout(() => window.close(), 15 * 1000); // close the tab after 15 seconds.
        }
        else {
            console.log(`RefreshTokenSuccess: No closeTab query parameter found`);
        }
    }, [location]);
    return (
        <div className="main">
            You are logged in successfully. Please close this tab and continue using the application.
        </div>
    );
}
