import React, { useEffect, useState } from 'react'
import { redirect, useParams } from 'react-router-dom';
import * as Session from "supertokens-auth-react/recipe/session";

let lastSentOtp = '';
console.log('Hello from RefreshToken');
async function refreshSession() {
    await Session.attemptRefreshingSession();
    let url = process.env.DEV === "Y" ? "https://localhost:8080/jwt/otp" : "https://identity.songtek.net/jwt/otp";
    const response = await fetch(url);

    if (!response.ok) {
        throw new Error(`Request failed with status ${response.status}`);
    }

    return await response.json();
}
export const RefreshToken: React.FC = () => {
    const [hasSession, setHasSession] = useState(false);
    const [otp, setOtp] = useState(null);
    useEffect(() => {
        Session.doesSessionExist()
            .then(hasSession => {
                setHasSession(hasSession);
                console.log("[RefreshToken] Session exists: " + hasSession);
                if (!hasSession) {
                    /**
                     * https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API
                     * a.com = https://analysis.songtek.net/
                     * b.com = https://hello2.songtek.net/
                     * 
                     * Note: To be exact, communication is allowed between browsing contexts using the same storage partition. 
                     * Storage is first partitioned according to top-level sites—so for example, if you have one opened page at a.com 
                     * that embeds an iframe from b.com, and another page opened to b.com, then the iframe cannot communicate with 
                     * the second page despite them being technically same-origin. However, if the first page is also on b.com, 
                     * then the iframe can communicate with the second page.
                     */
                    // commented out because it's not working in reality 
                    // const bc = new BroadcastChannel("auth_refresh_token_complete");
                    // bc.onmessage = (event) => {
                    //     console.log(event.data);
                    //     setHasSession(true);
                    // };
                    window.addEventListener("message", (e) => {
                        e.preventDefault();
                        const allowedOrigins = [
                            "https://hello2.songtek.net",
                            "https://hello.songtek.net",
                            "http://localhost:3200",
                            "https://localhost:3200",
                        ];
                        if (!allowedOrigins.includes(e.origin)) {
                            console.log("Skipped message invalid origin:", e.origin);
                            return;
                        }
                        const { name } = e.data;
                        if (name !== "RefreshTokenSuccess") {
                            console.log("Skipped message invalid name:", name);
                            return
                        }
                        console.log("[RefreshToken] Received message: ", e.data);
                        setHasSession(true);
                    });
                }
            })
            .catch(err => console.error(err));
    }, []); // load once
    useEffect(() => {
        if (!hasSession) return;
        refreshSession().then(data => setOtp(data.otp))
            .catch(err => console.error(err));
    }, [hasSession]); // load once
    if (!hasSession) {
        const loginUrl = `/auth/?redirectToPath=/RefreshTokenSuccess?closeTab=true`;

        const handleLoginClick = () => {
            // Open the login URL in a new tab (no referrer)
            /**
             * @see // https://developer.mozilla.org/en-US/docs/Web/API/Window/open#noopener
             * noopener
                If this feature is set, the new window will not have access to the originating window via Window.opener and returns null.

                When noopener is used, non-empty target names, other than _top, _self, and _parent, are treated like _blank in terms of deciding whether to open a new browsing context.

                noreferrer
                If this feature is set, the browser will omit the Referer header, as well as set noopener to true. See rel="noreferrer" for more information.
             */
            // must not use either "noreferrer" or "noopener" because it will block the communication between RefreshTokenSuccess in new tab and this one.
            window.open(loginUrl, "_blank"/*, "noreferrer"*/);
        };

        return (
            <div>
                <h2>Session not found.</h2>
                <button onClick={handleLoginClick}>
                    Please click here to login in a new tab
                </button>
            </div>
        );
    }
    if (!otp)
        return <div>Loading ...</div>;
    const inIframe = window.self !== window.top;
    if (inIframe) {
        let hashedOtp = (otp);
        if (hashedOtp !== lastSentOtp) {
            // console.log("Sending otpReady message to parent window: " + otp + " (" + hashedOtp + ")");
            console.log("Sending otpReady message to parent window.");
            // @ts-ignore
            window.top.postMessage({
                name: "otpReady",
                otp
            }, "*");
            lastSentOtp = hashedOtp;
        }

    }
    return (
        <div className="main">
            You are logged in successfully.
        </div>
    );
}
